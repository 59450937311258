import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ProvideAuth, useAuth } from '../hooks/useAuth';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';

const DashboardLayout = lazy(() => import('./DashboardLayout'));
const AuthBasicLayout = lazy(() => import('./AuthBasicLayout'));


const Layout = () => {
  return (
    <Suspense fallback={<span />}>
      <ProvideAuth>
        <Router fallback={<span />}>
          <Switch>
            <Route path="/auth" component={AuthBasicLayout} />
            <PrivateRoute path="/" component={DashboardLayout} />
          </Switch>
          <ToastContainer closeButton={<CloseButton />} transition={Fade} draggable={true} position={toast.POSITION.BOTTOM_CENTER}  />
        </Router>
      </ProvideAuth>
    </Suspense>
  );
};

export default Layout;

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { loggedIn } = useAuth()
  return (<Route
    {...rest}
    render={props =>
      loggedIn() ? (
        <Component {...props} />
      ) : <Redirect to={{ pathname: "/auth/login", search: `?next=${props.location.pathname}` }} />
    }
  />)
};